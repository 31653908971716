import React, { useState, useEffect } from "react"
import Layout from "@yumgmbh/gatsby-theme-yum-components/src/components/modules/Layout"
import SEO from "gatsby-theme-rothenberger/src/@yumgmbh/gatsby-theme-yum-components/components/modules/Seo"
import BlockWrapper from "@yumgmbh/gatsby-theme-yum-components/src/components/technical/BlockWrapper"
import Headline from "@yumgmbh/gatsby-theme-yum-components/src/components/atoms/Headline"
import Link from "@yumgmbh/gatsby-theme-yum-components/src/components/atoms/Link"

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faChevronLeft } from "@fortawesome/sharp-light-svg-icons"

const JobTemplate = ({ pageContext }) => {
  const jobId = pageContext.jobId
  const department = pageContext.department
  const employmentType = pageContext.employmentType
  const jobDescriptions = pageContext.jobDescriptions
  const name = pageContext.name
  const occupation = pageContext.occupation
  const occupationCategory = pageContext.occupationCategory
  const office = pageContext.office
  const recruitingCategory = pageContext.recruitingCategory
  const schedule = pageContext.schedule
  const seniority = pageContext.seniority
  const yearsOfExperience = pageContext.yearsOfExperience

  const jobApplicationUrl = pageContext.jobApplicationUrl

  return (
    <Layout language="de">
      <SEO title={`${name}`} description={`${name}`} />
      <BlockWrapper blockPadding="" block={{}}>
        <div className="block mb-2 -ml-2 text-sm leading-6">
          <a
            href="javascript:history.back()"
            className="px-2 text-ro-gray-400 hover:underline"
          >
            <FontAwesomeIcon
              icon={faChevronLeft}
              size="1x"
              className="inline-block align-middle -mt-0.5 -ml-0.5 mr-1"
            />
            Alle Stellen anzeigen
          </a>
        </div>
      </BlockWrapper>
      <BlockWrapper blockPadding="pb-block pt-10" block={{}}>
        <Headline headlineLevel="h1" headline={name} />
        <div className="w-full mt-8 prose lg:w-1/2 max-w-none">
          {jobDescriptions.jobDescription.map((jobDescription, index) => (
            <React.Fragment key={index}>
              <h3 className="headline headline-h4">{jobDescription.name}</h3>
              <div dangerouslySetInnerHTML={{ __html: jobDescription.value }} />
            </React.Fragment>
          ))}
        </div>

        <br />
        <Headline headlineLevel="h4" headline="Über ROTHENBERGER" />
        <div className="w-full mt-8 prose lg:w-1/2 max-w-none">
          <p>
            Seit über 70 Jahren ist ROTHENBERGER einer der weltweit führenden
            Hersteller für innovative, technologisch anspruchsvolle
            Rohrwerkzeuge und Rohrbearbeitungsmaschinen in der Sanitär-,
            Heizungs-, Klima-, Kälte-, Gas- und Umwelttechnik. Mit mehr als
            6.000 Produkten „Made by ROTHENBERGER“ ist ein Vollsortiment für den
            professionellen Anwender im Angebot. Dabei versteht sich
            ROTHENBERGER als Partner des Fachhandels für den Handwerker und für
            die Industrie.
          </p>
          <p>
            Eine Eigenproduktionsquote von ca. 60 % und mehr als 200 Patente und
            Warenzeichen belegen die Innovationskraft der ROTHENBERGER Produkte.
          </p>
        </div>

        <Link className="mt-8 btn btn-primary" link={jobApplicationUrl}>
          Jetzt bewerben
        </Link>
      </BlockWrapper>
    </Layout>
  )
}

export default JobTemplate
